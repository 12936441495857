<template>
  <a href="#main" class="sr-only">
    {{ label }}
  </a>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()

// These have to be hardcoded because the component is also used on
// maintenance pages where runtime translations are not available.
const LABELS: Record<string, string> = {
  en: 'Skip to main content',
  de: 'Direkt zum Inhalt springen',
  fr: 'Passer au contenu principal',
  it: 'Salta al contenuto principale',
}

const label = computed(() => LABELS[language.value])

defineOptions({
  name: 'SkipToMainContent',
})
</script>
